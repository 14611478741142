@media all and (max-width: 1200px) { /* screen size until 1200px */
    body {
        font-size: 1.5em; /* 1.5x default size */
    }
  }
  @media all and (max-width: 1000px) { /* screen size until 1000px */
    body {
        font-size: 1.2em; /* 1.2x default size */
        }
    }
  @media all and (max-width: 500px) { /* screen size until 500px */
    body {
        font-size: 0.8em; /* 0.8x default size */
        }
    }
  
  :root {
    --primary: #b45117;
    --primary-hover: #dd6017;
    /* PhoneInput for 'react-phone-number-input' */
    --PhoneInputCountryFlag-height: 15px!important;
  }

  html, body {
    background: #fff;
    color: #000;
    height: 100%;
  }

  #root {
    height: 100%!important;
    margin: 0;
    padding: 0;
  }

  form, .avatar-upload {
    width: 50%;
    margin: 0 auto;
  }

  form.interest-form {
    width: 100%;
  }

  .main-wrapper {
    min-height: 100%!important;
    padding-bottom: 210px;
  }

  .main {
    overflow: auto;
    padding-bottom: 210px; /* this needs to be bigger than footer height*/ 
  }

  #content {
    min-height: 100%!important;
  }

  #footer {
    background: rgb(245, 245, 245);
    position: relative;
    margin-top: -210px; /* negative value of footer height */
    height: 210px;
    padding-top: 40px;
    clear:both;
  }

  #footer small {
    display: block;
  }
  
  #footer .row {
    padding: 0;
    margin: 0;
  }
  
  .footer-menu ul li {
    display: inline-block;
  }
  
  .footer-menu ul {
    padding: 0;
  }

  .nav-menu a:hover {
    color: #e29024!important;
  }

  a:hover {
    text-decoration: none;
  }

  /* PhoneInput for 'react-phone-number-input' */
  .PhoneInputInput {
    color: #495057!important;
    border: none!important;
    outline: none!important;
  }

  .PhoneInputCountryIcon--border, .PhoneInputCountryIcon {
    box-shadow: none!important;
    background-color: rgba(255, 255, 255, 0)!important;
    line-height: 0px!important;
  }

  .btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  .btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:focus, .btn-primary:focus-within, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(180, 81, 23, .5);
  }

  .header {
    padding: 0;
  }
  
  .header .row {
    margin: 0;
  }
  
  .hero {
    background: #52280f;
    padding: 30px;
    background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.363), rgba(0, 0, 0, 0.801)),
    url('./components/landing/hero-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .logo {
    font-size: 3em;
    font-weight: 700;
    color: #e29024;
    text-shadow: 1.4px 1.4px #000;
  }

  .logo:hover {
    color: #e29024;
    text-shadow: 1.4px 1.4px #fff;
  }
  
  .launch-counter {
    color: #000;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.603);
    padding: 10px;
    font-size: 1.1em;
    font-weight: 600;
  }
  
  .why {
    text-decoration: underline;
    color: #000;
  }
  
  .why:hover {
    color: #e29024;
  }
  
  .share {
    color: #fff;
  }
  
  .share img {
    width: 60px;
  }
  
  .content .share {
    color: #000;
    font-size: 1.5em;
    width: 70%;
    margin: 0 auto;
  }
  
  .content .share img {
    width: 100px;
  }
  
  .header .share {
    font-size: 1.2em;
  }
  
  .tinder-logo {
    width: 70px;
  }
  
  .welcome {
    font-size: 2em;
    text-align: center;
    color: #fff;
  }
  
  .welcome h1 {
    font-size: 2em;
  }
  
  .content .desc {
    width: 70%;
    margin: 20px auto;
  }
  
  .call-to-action .desc {
    width: 70%;
    margin: 20px auto;
  }
  
  img.round {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .image-cropper img {
    width: 100px;
    height: 100px;
  }
  
  
  .sticky-mention {
    padding: 0;
  }
  
  .made-by {
    display: flex;
    background: #888;
    justify-content: space-around;
    align-items: flex-start;
    font-size: .9em;
  }
  
  .made-by:hover {
    font-size: .92em;
  }
  
  .mention {
    right: 0;
    bottom: 0;
    background-color: #0f1115;
    color: #fff;
    position: fixed;
    padding: .5em;
    font-weight: 500;
    background: #b45117;
    z-index: 4;
    border-top-left-radius: .2em;
  }
  
  .mention:hover {
    color: #fff;
    background: #c57008;
  }
  
  .mention img {
    border-radius: 0;
    width: 1.5em;
    vertical-align: middle;
    border-radius: 50%;
  }
  
  .mention span {
    margin: 0;
    vertical-align: middle;
    display: inline;
    margin-left: .5em;
    font-weight: 700;
  }
  
  .btn:hover {
    transform: translateY(-2px);
    opacity: 1;
  }
  
  .share-icon {
    margin: 0;
    padding: 0;
  }
  
  .copyright {
    opacity: .5;
  }
  
  .users {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .user {
    margin: 10px;
    transition: transform .2s ease-out;
    transition-delay: .3s;
  }

  .user:hover {
    transform: scale(1.1);
    transition: transform .2s ease-out;
  }

  .btn:disabled {
    cursor: not-allowed;
    transform: none;
    opacity: .6;
  }

  ul.cities-list {
    width: 100%;
    height: inherit;
    max-height: inherit;
    overflow: auto;
    list-style: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
  }

  ul.cities-list li {
    padding: 5px 5px;
    border-bottom: 1px solid rgb(235, 235, 235);
  }

  ul.cities-list li:last-child {
    border-bottom: none;
  }

  ul.cities-list li:hover {
    background: #007bff;
    color: #fff;
    cursor: pointer;
  }

  .city-list-container {
    width: 100%;
  }

  .d-block {
    display: block;
  }

  .d-none {
    display: none;
  }

  .text-red {
    color: red;
  }

  .privacy-agree {
    color: rgb(87, 87, 87);
    opacity: .7;
    font-size: .8em;
    width: 50%;
  }

  .alert-container {
    width: 50%;
    margin: 0 auto;
  }

  .privacy-agree a {
    color: rgb(0, 65, 150);
  }

  .footer-policy span {
    font-size: 80%;
    font-weight: 400;
    margin: 0 5px;
  }

  /* Utils */
  .opacity-4 {
    opacity: .4;
  }

  .opacity-5 {
    opacity: .5;
  }
  
  .opacity-8 {
    opacity: .8;
  }
  
  .text-section-border {
    border-left: 3px solid rgb(235, 235, 235);
    padding: 0 20px;
    font-size: 1.2em;
  }

  .how-it-works-page .tinder-logo {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.295);
    border-radius: 2px;
  }
  

  /* @media */
  @media (max-width: 576px), (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2), (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
    .user-9, .user-10, .user-11, .user-12, .user-13, .user-14, .user-15, .user-16, .user-17, .user-18, .user-19, .user-20, .user-21, .user-22, .user-23, .user-24, .user-25, .user-26, .user-27, .user-28, .user-29, .user-30, .user-31, .user-32, .user-33, .user-34, .user-35, .user-36 {
      display: none;
    }

    .create-profile-form-component h1, .create-profile-form-component h2, .create-profile-form-component h3, .create-profile-notice, .login-page h1, .register-page h1 {
      font-size: 2.1em;
    }

    .create-profile-form-component p.lead {
      font-size: 1.5em;
    }
  }

  /* All mobiles and regular iPad (not iPad Pro) */
  @media all and (max-width: 768px) { /* screen size until 500px */
    form, .avatar-upload {
      width: 100%;
    }

    .privacy-agree {
      width: 100%;
    }

    .made-by {
      display: none;
    }

    img.how-it-works-schema {
      width: 100%;
    }

    .alert-container {
      width: 100%;
    }
  }

  /* Landscape for all mobiles and regular iPad (not iPad Pro) */
  @media all and (max-height: 768px) and (orientation: landscape) { /* screen size until 500px */
    .made-by {
      display: none;
    }
  }